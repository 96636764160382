// function notEngTxt(constant) {
//   return `The field "${constant}" must be Cyrillic!`;
// }
function isRequired(constant) {
  return `Field "${constant}" is mandatory!`;
}
import home from "./home.json";
import table from "./table.json";
import confirmDialog from "./confirm-dialog.json";
import boards from "./boards.json";
import auth from "./auth.json";
import profile from "./profile.json";
import error from "./error.json";
import offersCatalog from "./offersCatalog.json";
import statistic from "./statistic.json";
import offer from "./offer.json";
import streams from "./streams.json";

export default {
  home: { ...home },
  confirmDialog: { ...confirmDialog },
  boards: { ...boards },
  auth: { ...auth },
  profile: { ...profile },
  error: { ...error },
  offersCatalog: { ...offersCatalog },
  statistic: { ...statistic },
  offer: { ...offer},
  streams: { ...streams},
  menuLocal: {
    home: "Home",
    boards: "Boards",
  },
  btn: {
    settings: "Settings",
    integrations: "Integrations",
    password_change: "Password change",
    translate: "Language",
    logout: "Logout",
    add: "Add",
    delete: "Delete",
    update: "Update",
    create: "Create",
    close: "Close",
    save: "Save",
    back: "Back",
    cancel: "Cancel",
    apply: "Apply",
    confirm: "Confirm",
    edit: "Edit",
    all: "All",
    archiveRestore: "Restore",
    addTime: "Add time",
    selectTime: "Select Time",
    HintTestCases: "Test cases",
  


    // new translations
    remove_offer:"Remove offer",
    remove_article:"Remove article",
    add_a_picture: "Add a picture",
    delete_picture: "Delete picture",
    fullDescriptionCollapse: "Collapse",
    fullDescriptionReadinfull:"Read in full",
    create_a_stream: "Create a stream",
    details:"Details",
    delete_user:"Delete user",
    add_an_order:"Add an order",
    addNews:"Add news",


  },
  settings: {
    name: "Name",
    country_code: "Country Code",
    actions: "Actions",
    price: "Price",
    discount: "Discount",
    phone_number: "Phone Number",
    icon: "Icon",
  },
  
  sideBar: {
    main: "Main",
    additional: "Additional",
    panel: "Panel",
    news: "News",
    orders: "Orders",
    offersCatalog: "Offers catalog",
    streams: "Streams",
    statistic: "Statistic",
    transactions: "Transactions",
    settings: "Settings",
    admin_panel: "Admin panel",
    statistic_seller: "Statistic (seller)",
    transactions_seller: "Transactions (seller)",
    users: "Users"
  },
  table: {
    actions: "Actions",
    user_name: "User Name",
    email: "Email",
    phone_number: "Phone Number",
    role: "Role"
  },
  form: {
    advertorials: "Advertorials",
    Userisundefined: "The field must not be empty",
    email: "E-mail",
    password: "Password",
    name: "Name",
    surname: "Surname",
    phone_number: "Phone number",
    balance: "Balance",
    search: "Search",
    title: "Name",
    deleteSuccess: "Successfully deleted",
    title_setting: "Settings",
    phone: "Phone number",
    Curpassword: "Current pass",
    NewPass: "New pass",
    Confirpassword: "Confirm pass",
    ChangePhoto: "Change photo",
    Tasks: "Tasks",
    Profile: "Profile",
    username: "Username",
    nickname: "Name",
    deluserboard: "User successfully deleted",
    adduserboard: "User successfully added",
    addSuccess: "Successfully added",
    workloadCc: "Workload CC",


    // new translations
    duplicateCategoryError:"The category name has already been taken.",
    duplicateGeoError:"The geo name has already been taken.",
    description: "Description",
    instock:"In stock",
    dropprice: "Drop price:",
    country: "Country",
    the_goal_is_Redemption: "The goal is Redemption",
    price_drop: "Price drop",
    retail_price: "Retail price",
    estimated_profit:"Estimated profit",
    call_center:"Call center",
    works:"Works 7 days a week: 8:00-22:00 Kyiv time (languages UA, ​​RU)",
    code:"Code",
    geo:"Geo(Restrictions)",
    additional: "Additional",
    You_may_be_interested:"You may be interested",
    country_code:"Country code",
    add_location:"Add the location",
    add_crm:"Add the CRM",
    add_office:"Add office",
    add_a_category:"Add a category",
    landings:"Landings",
    UAH:"UAH",
    streams:"Streams",
    role:"Role",
    edit_user:"Edit user",
    edit_geo:"Edit location",
    edit_office:"Edit office",
    edit_crm:"Edit CRM",
    office:"Office",
    offices:"Offices",
    edit_article:"Edit article",
    client_name:"Customer name",
    create_an_order:"Create an order",
    create_a_news:"Create news",
    edit_a_news:"Edit news",
    processing_type:"Processing type",
    status:"Status",
    statuses:"Statuses",
    number: "Number",
    all: "All",
    new: "New",
    not_processed: "Not Processed",
    missed_call: "Missed Call",
    in_progress: "In Progress",
    confirmed: "Confirmed",
    rejected: "Rejected",
    shipped: "Awaiting redemption",
    not_purchased: "Not Purchased",
    purchased: "Purchased",


    salesStatistics:"Sales statistics",
    trafficStatistics:"Traffic statistics",


    no_landings:"There are no landings in this stream!",

    image:"Image",
    margin:"Margin",
    important:"Important",
    worldNews:"World news",

    UTMTags:"UTM tags",
    AddLabels:"Additional UTM tags",

    GEO:"GEO",
    CATEGORIES:"CATEGORIES",
    characteristics:"Characteristics",
    offers:"Offers",

    prices:'Prices',
    payment:'Payment',
    deleteImgDialog: {
      DelImg: "Delete image",
      Confirm: "Confirm the deletion",
      Textconfirm: "Are you sure that you want to delete this image?",
      delete_dialog_title: "Delete",
      delete_dialog_desc: "Do you want to delete it?",
    },
    deleteConfirmDialog: {
      Confirm: "Confirm the deletion",
      Textconfirm: "Do you want to delete it?",
      delete_dialog_title: "Delete",
    },
    archiveDialog: {
      archiveTitle: "Archive",
      archive_nameTask: "Ticket",
      archive_dataDelete: "Date of deletion"
    },
    errors: {
      NameRequired: "Name is a required field!",
      TitleRequired: "Title is a required field!",
      geoRequired: "Geo is a required field!",
      SurnameRequired: "Surname is a required field!",
      PriceMoreThanRequired: "Price must be bigger, than offer price",
      TimeRequired: "Time field is required!",

      EmailRequired: "Email is a required field!",

      EmailMustBeValid: "Email must be valid!",
      EmailalreadyExists: "This email already exists.",
      nicknamereg: "Nickname is a required field!",
      PasswordRequired: "Password is a required field!",
      PasswordsDontMatch: "Passwords don't match!",
      PasswordMustBeLike:
        "Choose a stronger password. Try a combination of letters, numbers, and symbols. The password must contain at least one uppercase letter. The minimum password length must be 8 characters.",
      PhoneNumberRequired: "Phone number is a required field!",
      PhoneNumberMustBeLike:
        "The phone number should include: +(380)-000-000-000",
      photoMaxSize: "The photo must be less than 8 MB!",
      ListAndNameRequired: "List and Name are required",
      registerUsername: "The username has already been taken.",
      Selectedimageerror: "Selected file is not an image",
      loginError: "Your login info is not correct",
      required: "Field is required!",

      ConfirmPassRequired:"Password confirmation is a required field!",
      PasswordRequired: "Password is a required field!",

      //new 
      
      PriceAboveDrop:"The price must be higher than the drop price",
      geoRequired: "Geo is a required field!",
      PriceRequired: "Price is a required field!",
      PayoutRequired: "Payout is a required field!",
      PayoutModelRequired: "Payout type is a required field!",
      DiscountRequired: "Discount is a required field!",
      OfferRequired: "Offers is a required field!",
      MarginRequired: "Margin is a required field!",
      PasswordMustContainUppercase: "Password must contain an uppercase letter!",
      DescriptionRequired: "Description is a required field!",
      CurrentPassRequired:"Password is a required field!",
      InvalidPriceFormat: "Invalid price format. Use numbers with up to two decimal places.",
      streamRequired:"Stream is a required field!",
      CRMRequired:"CRM PRODUCT ID  is a required field!",
      DiscountCannotExceed99: "Discount cannot exceed 99",
      UrlRequired:"URL is a required field!",
      CrmIdRequired:"CRM ID is a required field!",
      inputTokenRequired:"Input Token is a required field!",
      outputTokenRequired:"Output Token is a required field!",
    },
    checklistDialog: {
      addTestCase: "Add test case",
      taskList: "Task list",
    },
    
    noData: "No data!",
    time: "Time",
    day_singular: "day",
    day_few: "days",
    day_many:'days',
  },
  headers: {
    date: "Date",
    totalClicks: "Total Clicks",
    uniqueClicks: "Unique Clicks",
    cr: "CR %",
    approve: "Approve %",
    purchased: "Purchased %",
    epc: "EPC",
    expectedIncome: "Expected Income, UAH",
    totalOrders: "Total Orders",
    newCount: "New Count",
    inProgressCount: "In Progress Count",
    confirmedCount: "Confirmed Count",
    rejectedCount: "Rejected Count",
    shippedCount: "Shipped Count",
    notPurchasedCount: "Not Purchased Count",
    purchasedCount: "Purchased Count",
    missedCallCount: "Missed Call Count",
    newIncome: "New Income, UAH",
    inProgressIncome: "In Progress Income, UAH",
    confirmedIncome: "Confirmed Income, UAH",
    rejectedIncome: "Rejected Income, UAH",
    shippedIncome: "Shipped Income, UAH",
    notPurchasedIncome: "Not Purchased Income, UAH",
    purchasedIncome: "Purchased Income, UAH",
    missedCallIncome: "Missed Call Income, UAH",
    actualIncomeUAH: "Actual Income, UAH",
    actualEPC: "Actual EPC",
    TotalNumberOfOrders: "Total Orders",
    potentialIncome: "Potential Income",
    actualIncome: "Actual Income",
    id_transactions: "Transactions ID",
    id_order: "Order ID",
    operations: "Operation (Accruals/Deductions)",
    sum: "Sum",
    balance_after_operation: "Balance after operation",
    comment: "Comment",
    name: "Name",
  },

  users: {
    successfully_deleted: "User successfully deleted",
    name: "Name"
  }
};
