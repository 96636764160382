// function notEngTxt(constant) {
//   return `Поле «${constant}»  повинне бути кирилицею!`;
// }

function isRequired(constant) {
  return `Поле «${constant}»  обов'язкове для заповнення!`;
}
import home from "./home.json";
import table from "./table.json";
import confirmDialog from "./confirm-dialog.json";
import boards from "./boards.json";
import auth from "./auth.json";
import profile from "./profile.json";
import error from "./error.json";
import offersCatalog from "./offersCatalog.json";
import statistic from "./statistic.json";
import offer from "./offer.json";
import streams from "./streams.json";

export default {
  home: { ...home },
  confirmDialog: { ...confirmDialog },
  boards: { ...boards },
  auth: { ...auth },
  profile: { ...profile },
  error: { ...error },
  offersCatalog: { ...offersCatalog },
  statistic: { ...statistic },
  offer: { ...offer},
  streams: { ...streams},
  menuLocal: {
    home: "Головна",
    boards: "Дошки",
  },
  btn: {
    settings: "Налаштування",
    integrations: "Інтеграції",
    password_change: "Зміна паролю",
    translate: "Мова",
    logout: "Вийти",
    add: "Додати",
    delete: "Видалити",
    update: "Оновити",
    create: "Створити",
    close: "Закрити",
    save: "Зберегти",
    back: "Назад",
    cancel: "Скасувати",
    apply: "Застосувати",
    confirm: "Підтвердити",
    edit: "Редагувати",
    all: "Всі",
    archiveRestore: "Відновити",
    addTime: "Додати час",
    selectTime: "Вибрати Час",
    HintTestCases: "Тест кейси",
    
    



    // new translations
    remove_offer:"Видалити оффер",
    remove_article:"Видалити новину",
    add_a_picture: "Додати картинку",
    delete_picture: "Видалити картинку",
    fullDescriptionCollapse: "Згорнути",
    fullDescriptionReadinfull:"Читати повністю",
    create_a_stream: "Створити потік",
    details:"Деталі",
    delete_user:"Видалити користувача",
    add_an_order:"Додати замовлення",
    addNews:"Додати новину",

  },
  settings: {
    name: "Назва",
    country_code: "Код країни",
    actions: "Дії",
    price: "Ціна",
    discount: "Знижка",
    phone_number: "Номер телефону",
    icon:"Іконка",
  },

  sideBar: {
    main: "Основне",
    additional: "Додатково",
    panel: "Панель",
    news: "Новини",
    orders: "Замовлення",
    offersCatalog: "Каталог офферів",
    streams: "Потоки",
    statistic: "Статистика",
    transactions: "Транзакції",
    settings: "Налаштування",
    admin_panel: "Адмін панель",
    users: "Користувачі",
    statistic_seller: "Статистика (рекламодавець)",
    transactions_seller: "Транзакції (рекламодавець)",
  },
  table: {
    actions: "Дії",
    user_name: "Ім'я користувача",
    email: "Email",
    phone_number: "Номер телефону",
    role: "Роль",
  },
  form: {
    advertorials: "Адверторіали",
    Userisundefined: "Поле не повинно бути порожнім",
    email: "E-mail",
    password: "Пароль",
    name: "Ім'я",
    surname: "Прізвище",
    phone_number: "Номер телефону",
    balance: "Баланс",
    search: "Пошук",
    title: "Назва",
    deleteSuccess: "Успішно видалено",
    title_setting: "Налаштування",
    phone: "Номер телефону",
    Curpassword: "Поточний пароль",
    NewPass: "Новий пароль",
    Confirpassword: "Підтвердити палоль",
    ChangePhoto: "Змінити фото",
    Tasks: "Завдання",
    Profile: "Профіль",
    username: "Ім'я користувача",
    nickname: "Назва користувача",
    deluserboard: "Користувач успішно видалений",
    adduserboard: "Користувача успішно додано",
    addSuccess: "Успішно додано",
    workloadCc: "Завантаженість КЦ",
    UAH:"грн",

    // new translations
    duplicateCategoryError:"Назва категорії вже зайнята.",
    duplicateGeoError:"Назва геолокації вже зайнята.",
    description: "Опис",
    instock:"В наявності",
    dropprice: "Ціна дроп:",
    country: "Країна",
    the_goal_is_Redemption: "Ціль-Викуп",
    price_drop: "Ціна дропа",
    retail_price: "Роздрібна ціна",
    estimated_profit:"Орієнтовний прибуток",
    call_center:"Колцентр",
    works:"Працює 7 днів на тиждень: 8:00-22:00 за Києвом (мови UA, RU)",
    code:"Код",
    geo:"Гео(Обмеження)",
    additional: "Додатково",
    You_may_be_interested:"Вас може зацікавити",
    discount:"Знижка",
    country_code:"Код країни",
    add_a_category:"Додати категорію",
    add_location:"Додати локацію",
    add_crm:"Додати CRM",
    add_office:"Додати офіс",
    landings:"Лендінги",
    streams:"Потоки",
    role:"Роль",
    edit_user:"Редагувати користувача",
    edit_geo:"Редагувати локацію",
    edit_office:"Редагувати офіс",
    edit_crm:"Редагувати CRM",
    office:"Офіс",
    offices:"Офіси",
    edit_article:"Редагувати новину",
    client_name:"Ім'я клієнта",
    create_an_order:"Створити замовлення",
    create_a_news:"Створити новину",
    edit_a_news:"Редагувати новину",
    processing_type:"Тип обробки",
    status:"Статус",
    statuses:"Статуси",
    number: "Номер",
    all: "Всі",
    new: "Нові",
    not_processed: "Не оброблено",
    missed_call: "Недодзвон",
    in_progress: "В обробці",
    confirmed: "Підтверджений",
    rejected: "Відмова",
    shipped: "Очікують викупу",
    not_purchased: "Невикуп",
    purchased: "Викуп",
    
      
    salesStatistics:"Статистика продаж",
    trafficStatistics:"Статистика трафіка",

    no_landings:"Лендінги в даному потоці відсутні!",

    image:"Зображення",

    margin:"Маржа",

    new:"Нове",
    important:"Важливе",
    worldNews:"Новини світу",

    UTMTags:"UTM мітки",
    AddLabels:"Дод. мітки",

    GEO:"ГЕО",
    CATEGORIES:"КАТЕГОРІЇ",


    characteristics:"Характеристики",

    offers:"Оффери",


    prices:'Ціни',
    payment:'Виплата',
    deleteImgDialog: {
      DelImg: "Видалити картинку",
      Confirm: "Підтвердження видалення",
      Textconfirm: "Ви впевнені що хочете видалити дану картинку?",
      delete_dialog_title: "Видалити",
      delete_dialog_desc: "Ви справді хочете видалити це?",
    },
    deleteConfirmDialog: {
      Confirm: "Підтвердження видалення",
      Textconfirm: "Ви справді хочете видалити це?",
      delete_dialog_title: "Видалити",
    },
    archiveDialog: {
      archiveTitle: "Архів",
      archive_nameTask: "Назва задачі",
      archive_dataDelete: "Дата видалення"
    },
    errors: {
      NameRequired: "Ім'я є обов'язковим полем!",
      TitleRequired: "Назва є обов'язковим полем!",
      SurnameRequired: "Прізвище обов'язкове!",
      PriceMoreThanRequired: "Ціна повинна бути більша, аніж ціна офферу",
      TimeRequired: "Поле час обов'язкове!",
      EmailRequired: "Email є обов'язковим полем!",
      EmailMustBeValid: "Email повинен бути валідним!",
      EmailalreadyExists: "Такий email вже існує.",
      nicknamereg: "Нікнейм є обов'язковим полем!",
      PasswordRequired: "Пароль є обов'язковим полем!",
      PasswordsDontMatch: "Паролі не співпадають!",
      PasswordMustBeLike:
        "Виберіть надійніший пароль. Спробуйте комбінацію букв, цифр і символів. У паролі має бути як мінімум одна велика літера. Мінімальна довжина паролю повинна становити 8 символів.",
      PhoneNumberRequired: "Номер телефону є обов'язковим полем!",
      PhoneNumberMustBeLike: "Телефон повинен містити: +(380)-000-000-000!",
      photoMaxSize: "Фото повинно бути менше 8 МБ!",
      ListAndNameRequired: "Список та назва обов'язкові!",
      registerUsername: "Ім'я користувача вже зайнято.",
      Selectedimageerror: "Вибраний файл не є зображенням",
      loginError: "Ваші дані для входу невірні",
      required: "Поле обов'язкове для заповнення!",
      ConfirmPassRequired:"Підтвердження паролю є обов'язковим полем!",
      CurrentPassRequired:"Пароль є обов'язковим полем!",
      UrlRequired:"URL є обов'язковим полем!",
      CrmIdRequired:"CRM ID є обов'язковим полем!",
      inputTokenRequired:"Input Token є обов'язковим полем!",
      outputTokenRequired:"Output Token є обов'язковим полем!",


      //new
      
      PriceAboveDrop:"Ціна повинна бути вищою, аніж ціна дроп",
      geoRequired: "Гео є обов'язковим полем!",
      PriceRequired: "Ціна є обов'язковим полем!",
      PayoutRequired: "Виплата є обов'язковим полем!",
      PayoutModelRequired: "Тип виплати є обов'язковим полем!",
      DiscountRequired: "Знижка є обов'язковим полем!",
      OfferRequired: "Оффери є обов'язковим полем!",
      PasswordMustContainUppercase: "Пароль повинен містити велику літеру!",
      MarginRequired: "Маржа є обов'язковим полем!",
      DescriptionRequired: "Опис є обов'язковим полем!",
      streamRequired:"Потоки є обов'язковим полем!",
      CRMRequired:"CRM PRODUCT ID є обов'язковим полем!",
      DiscountCannotExceed99: "Знижка не може перевищувати 99"
    },
    checklistDialog: {
      addTestCase: "Додати тест-кейс",
      taskList: "Список завдань",
    }, 
    //new


    noData: "Даних немає!",
    time: "Час",
    day_singular: "день",
    day_few: "дні",
    day_many:'днів',
  },
  headers: {
      date: "Дата",
      totalClicks: "Кліків",
      uniqueClicks: "Уніків",
      cr: "CR %",
      approve: "Апрув %",
      purchased: "Викуп %",
      epc: "EPC",
      expectedIncome: "Плановий дохід, грн.",
      totalOrders: "Замовлень загалом",
      newCount: "Новий к-сть",
      inProgressCount: "Обробка к-сть",
      confirmedCount: "Підтв. к-сть",
      rejectedCount: "Відм. к-сть",
      shippedCount: "Відпр. к-сть",
      notPurchasedCount: "Невикуп. к-сть",
      purchasedCount: "Викуп. к-сть",
      missedCallCount: "Недодз. к-сть",
      newIncome: "Новий, грн.",
      inProgressIncome: "Обробка, грн.",
      confirmedIncome: "Підтв., грн.",
      rejectedIncome: "Відм., грн.",
      shippedIncome: "Відп., грн.",
      notPurchasedIncome: "Невикуп., грн.",
      purchasedIncome: "Викуп., грн.",
      missedCallIncome: "Недодз., грн.",
      actualIncomeUAH: "Фактичний дохід, грн.",
      actualEPC: "EPC, факт",
      TotalNumberOfOrders: "Загальна кількість замовлень",
      potentialIncome: "Потенційний дохід із загальної кількості замовлень",
      actualIncome: "Фактичний дохід",
      id_transactions: "ID транзакції",
      id_order: "ID замовлення",
      operations: "Операції (нарахування/списання)",
      sum: "Сума",
      balance_after_operation: "Баланс після операції",
      comment: "Коментар",
      name: "Ім'я",
    },

    
      users: {
        successfully_deleted: "Користувача успішно видалено",
        name: "Ім'я"
      }
    
  
};
